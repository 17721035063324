<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">{{ $t("ANALYTICS.OFFICE_ATTENDANCE_TITLE") }}</h3>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin: Search Form-->
      <div class="mb-7">
        <div class="row align-items-center">
          <div class="col-lg-12 col-xl-12">
            <div class="row align-items-center">
              <div class="col-md-2 my-2 my-md-0">
                <label class="mr-3 d-none d-md-block">{{ $t("ANALYTICS.PERIOD_TITLE") }}</label>
                <treeselect v-model="filter_period"
                            :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                            :multiple="false"
                            @select="setPeriod"
                            :normalizer="normalizer"
                            :clearable="false"
                            :limit="1"
                            :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                            :options="periodList">
                  <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
                </treeselect>
              </div>
              <div class="col-md-2 my-2 my-md-0">
                <label class="mr-3 d-none d-md-block">{{ $t("ANALYTICS.ACCESS_POINTS_TITLE") }}</label>
                <treeselect v-model="filter_access_points"
                            :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                            :multiple="true"
                            :normalizer="normalizer"
                            clearAllText="Очистить всё"
                            :limit="1"
                            :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                            :options="accessPointList.items">
                  <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
                </treeselect>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end: Search Form-->
      <apexchart type="area" height="350" :options="categoriesCounter" :series="dateCounter"></apexchart>
      <p class="custom-title text-center align-content-center p-0 m-0">{{ $t("ANALYTICS.TOTAL_VISITORS_POINT") }}</p>
    </div>
    <!--end::Body-->
    <!--begin::Edit Period Date Modal-->
    <EditPeriodDateModal
        @changePeriodDate="changePeriodDate"/>
    <!--end::Edit Period Date Modal-->
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import i18nService from "@/core/services/i18n.service.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_ACCESS_POINT_LIST, GET_ATTENDANCE_ACCESS_POINTS } from "@/core/services/store/analytics/attendance.module";

import EditPeriodDateModal from "@/view/layout/modal/EditPeriodDateModal";

export default {
  name: "OfficeAttendance",
  components: {
    EditPeriodDateModal
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("ANALYTICS.OFFICE_ATTENDANCE_TITLE") }]);
    this.$store.dispatch(GET_ACCESS_POINT_LIST);
    this.$store.dispatch(GET_ATTENDANCE_ACCESS_POINTS, this.filters);
  },
  data() {
    return {
      filters: {
        from: moment(new Date()).add(-6, 'days').format('DD.MM.YYYY'),
        to: moment(new Date()).format('DD.MM.YYYY')
      },
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        }
      },
      filter_period: 1,
      filter_access_points: null,
      optionsDatePicker: {
        locale: i18nService.getActiveLanguage(),
        format: 'DD.MM.YYYY',
        useCurrent: true,
        showClear: false,
        showClose: false,
        maxDate: new Date()
      }
    }
  },
  watch: {
    filter_access_points(val) {
      if (val.length > 0) this.filters.accessPoints = val;
      else delete this.filters.accessPoints;
      this.$store.dispatch(GET_ATTENDANCE_ACCESS_POINTS, this.filters);
    }
  },
  computed: {
    ...mapGetters([
      'periodList',
      'accessPointList',
      'attendanceAccessPointsData'
    ]),
    categoriesCounter() {
      const categories = this.attendanceAccessPointsData.map(item => item.date);
      const categoriesAnnotations = [];
      this.attendanceAccessPointsData.forEach(item => {
        if (item.is_holiday) {
          categoriesAnnotations.push({
            x: moment(item.date, 'DD/MM/YYYY').format('DD MMM'),
            strokeDashArray: 9,
            borderColor: '#C1D3E1',
            label: {
              show: true,
              text: this.$t("ANALYTICS.NO_WORK_DAYS"),
              borderWidth: 0,
              offsetX: 21,
              style: {
                color: '#9396AB',
                fontSize: '12px',
                fontWeight: 300,
                background: '#EBEFF2'
              }
            }
          })
        }
      })
      return {
        chart: {
          type: 'area',
          height: 350,
          animations: {
            enabled: true
          },
          zoom: {
            enabled: false
          },
        },
        colors: ['#626590'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        fill: {
          colors: '#626590',
          opacity: 0.1,
        },
        markers: {
          colors: '#FFFFFF',
          strokeColors: '#626590',
          size: 5,
          hover: {
            size: 9
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            },
            title: {
              formatter: () => this.$t("ANALYTICS.TOTAL_VISITORS_POINT") + ':',
            },
          }
        },
        annotations: {
          xaxis: categoriesAnnotations
        },
        xaxis: {
          // title: {
          //   text: 'Всего посетителей',
          //   style: {
          //     color: '#82839C',
          //     fontSize: '12px',
          //     fontWeight: 900,
          //   },
          // },
          categories: categories,
          labels: {
            formatter: function (value) {
              return moment(value, 'DD/MM/YYYY').locale(i18nService.getActiveLanguage()).format('DD MMM');
            }
          }
        },
        yaxis: {
          title: {
            text: this.$t("ANALYTICS.TOTAL_VISITORS_POINT"),
            style: {
              color: '#9396AB',
              fontSize: '12px',
              fontWeight: 300,
            },
          },
        }
      };
    },
    dateCounter() {
      const arrayValues = this.attendanceAccessPointsData.map(item => item.value);
      return [
        {
          name: this.$t("ANALYTICS.TOTAL_VISITORS_POINT"),
          data: arrayValues
        }
      ]
    }
  },
  methods: {
    onChangeDatePicker() {
      this.$store.dispatch(GET_ATTENDANCE_ACCESS_POINTS, this.filters);
    },
    setPeriod(val) {
      if (val.id === 1) {
        this.filters.from = moment(new Date()).add(-6, 'days').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val.id === 2) {
        this.filters.from = moment(new Date()).add(-14, 'days').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val.id === 3) {
        this.filters.from = moment(new Date()).add(-29, 'days').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val.id === 4) {
        this.filters.from = moment(new Date()).startOf('month').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val.id === 5) {
        this.filters.from = moment().subtract(1, 'months').startOf('month').format('DD.MM.YYYY');
        this.filters.to = moment().subtract(1, 'months').endOf('month').format('DD.MM.YYYY');
      } else if (val.id === 6) {
        this.$bvModal.show('modal-edit-period-date');
      }
      if (val && val.id !== undefined && val.id !== 6) {
        this.$store.dispatch(GET_ATTENDANCE_ACCESS_POINTS, this.filters);
      }
    },
    changePeriodDate(newDate) {
      this.filters.from = newDate.from;
      this.filters.to = newDate.to;
      this.$store.dispatch(GET_ATTENDANCE_ACCESS_POINTS, this.filters);
    },
  }
}
</script>

<style lang="scss" scoped>
  .custom-title {
    color: #82839C;
    font-size: 13px;
    font-weight: 900;
    &:before{
       content: '';
       display: inline-block;
       width: 10px;
       height: 10px;
       margin-right: 10px;
       border-radius: 50%;
       background-color: #5B5E8B;
     }
  }
</style>
